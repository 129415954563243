import React from "react"
import SEO from "../components/seo"
import Layout from "../containers/Layout"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Container } from "../components/Container"
import { useProjectByLang } from "../hooks"
import ProjectList from "../sections/ProjectList"
import Points from "../assets/points.svg"

const Projects = () => {
  const projectList = useProjectByLang()
  return (
    <Layout>
      <SEO intlId="projects" />
      <Design />
      <Container>
        <div className="w-full">
          <h1 className="text-3xl font-semibold">
            <FormattedMessage id="projects.title" />
          </h1>
        </div>
        <p>
          <FormattedMessage id="projects.text" />
        </p>
        <br />
        <ProjectList list={projectList} />
      </Container>
    </Layout>
  )
}

function Design() {
  return (
    <>
      <small
        style={{
          width: "10%",
          display: "block",
          right: 0,
          bottom: "10%",
          position: "absolute",
          opacity: "0.8",
        }}
      >
        <Points />
      </small>

      <small
        style={{
          width: "10%",
          display: "block",
          left: -90,
          top: "15%",
          position: "absolute",
          opacity: "0.8",
        }}
      >
        <Points />
      </small>
      <small></small>
    </>
  )
}

export default Projects
