import React from "react"
import { GitHubButton, ProjectButton } from "../components/Buttons"
import { FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"

export default function ProjectList({ list = [] }) {
  return (
    <div className={"w-full mb-4 p-2"}>
      {list.sort((a, b) => new Date(b.buildAt) - new Date(a.buildAt)).map(({ node: { name, description, github, online, image } }, i) => {
        const switchColums = i % 2

        return (
          <div className="xs:block xs:my-8 md:flex">
            {switchColums ? (
              <>
                <ProjectDefinition
                  key={i}
                  name={name}
                  description={description.description}
                  github={github}
                  online={online}
                />
                <ProjectImage alt={name} image={image} />
              </>
            ) : (
              <>
                <ProjectImage alt={name} image={image} />
                <ProjectDefinition
                  key={i}
                  name={name}
                  description={description.description}
                  github={github}
                  online={online}
                />
              </>
            )}
            <br />
            <hr className="xs:block my-8 md:hidden" />
            <br />
          </div>
        )
      })}
    </div>
  )
}

function ProjectImage({ image, alt }) {
  return (
    <div className="flex-1">
      {image && (
        <Img className="md:w-2/3 md:mx-auto" fluid={image.fluid} alt={alt} />
      )}
    </div>
  )
}

function ProjectDefinition({ name, description, github, online }) {
  return (
    <div className="flex-1 self-center">
      <div className="w-3/4">
        <h2 className="mb-6 text-xl text-blue-700">{name}</h2>
        <div>
          <p className="text-gray-700">{description}</p>{" "}
        </div>
        <div className="mt-5">
          {online && (
            <ProjectButton link={online}>
              <FormattedMessage id="viewProject" />
            </ProjectButton>
          )}
          {github && (
            <GitHubButton link={github}>
              <FormattedMessage id="viewOnGithub" />
            </GitHubButton>
          )}
        </div>
      </div>
    </div>
  )
}
