import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

export const useLangFilter = arr => {
  const { locale } = useIntl()
  return arr.filter(({ node }) => node.node_locale.split("-")[0] === locale)
}

export const useProjectByLang = () => {
  const { allContentfulProject } = useStaticQuery(
    graphql`
      {
        allContentfulProject {
          edges {
            node {
              id
              name
              online
              github
              labels
              buildAt
              node_locale
              description {
                description
              }
              image {
                fluid(maxWidth: 786) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              contentful_id
            }
          }
        }
      }
    `
  )

  return useLangFilter(allContentfulProject.edges)
}
